<script lang="ts" setup>
import { GetBlockUIButton } from '@getblockui/core';
import { computed, nextTick, onMounted, watch } from 'vue';
import { useStore } from '@/store';
import { onboardingData } from '@/components/onboarding/onboardingData';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useGtm } from '@gtm-support/vue-gtm';

const store = useStore();
const { t } = useI18n();
const gtm = useGtm();
const router = useRouter();

const stepNumber = computed(() => store.state.general.onboardingData.stepNumber);
const type = computed(() => store.state.general.onboardingData.type);
const isDataLoading = computed(() => store.state.general.loading);
const countOfSteps = computed(() => onboardingData[type.value] ? onboardingData[type.value].length : 0);

const data = computed(() => {
  const key = `onboarding.${type.value}.${stepNumber.value}`;
  return {
    title: t(`${key}.title`),
    description: t(`${key}.description`),
    button: t(`${key}.button`),
    stepNumber: stepNumber.value + 1,
  };
});

const handleContinueClick = () => {
  if (stepNumber.value + 1 < onboardingData[type.value].length) {
    store.commit('setOnboardingData', { stepNumber: stepNumber.value + 1 });
    return;
  }

  store.commit('setShowOnboarding', {
    stepsModal: false,
  });
};

const handleSkipTutorialClick = () => {
  gtm?.trackEvent({
    event: 'skip_tutorial',
    userID: store.state.user.id,
  });
  store.commit('setShowOnboarding', {
    stepsModal: false,
  });
};

watch(() => [store.state.general.onboardingData, isDataLoading.value], async () => {
  if (!isDataLoading.value) {
    // note: this code clear previous step highlighted elements
    if (stepNumber.value >= 1) {
      const { page, elementsToHighlight } = onboardingData[type.value][stepNumber.value - 1];
      elementsToHighlight.forEach((element) => {
        const foundedElement = document.querySelector(`.${element}`);
        if (foundedElement) {
          foundedElement.style.zIndex = 'auto';
          if (type.value === 'base') {
            foundedElement.style.pointerEvents = 'auto';
          }
        }
      });
    }
    const { page, elementsToHighlight } = onboardingData[type.value][stepNumber.value];
    await router.push(page);
    elementsToHighlight.forEach((element) => {
      const foundedElement = document.querySelector(`.${element}`);
      if (foundedElement) {
        foundedElement.style.zIndex = 21;
        if (type.value === 'base') {
          foundedElement.style.pointerEvents = 'none';
        }
      }
    });
  }
}, { immediate: true });
</script>

<template>
  <div
    class="modal fixed z-20 top-0 left-0 h-full w-full flex justify-center items-center xs:items-end xs:left-0 xs:right-0"
  ></div>
  <div class="fixed flex flex-col gap-4 z-[21] top-6 right-6 w-[320px] p-4 gap-4 rounded-20"
       style="background: linear-gradient(271deg, #DB87E9 0.39%, #9343FC 47.15%, #391771 99.59%);"
  >
    <div class="flex flex-col gap-1 max-w-[240px]">
      <h3 class="font-bold leading-5 text-text-color-bg">{{ data.title }}</h3>
      <p class="text-sm leading-5 text-text-color-bg">{{ data.description }}</p>
    </div>
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row gap-1.5">
        <GetBlockUIButton color-type="white" size-type="small" @click="handleContinueClick">{{ data.button }}
        </GetBlockUIButton>
        <GetBlockUIButton
          v-if="stepNumber !== countOfSteps - 1"
          color-type="tiny-white" size-type="small" @click="handleSkipTutorialClick"
        >
          {{ $t('onboarding.skip-tutorial') }}
        </GetBlockUIButton>
      </div>
      <div class="flex items-center justify-center h-8 w-8 rounded-12" style="background: rgba(255, 255, 255, 0.16);">
        <p class="font-bold leading-5 text-text-color-bg">{{ data.stepNumber }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  backdrop-filter: blur(3px);
  background: rgba(44, 44, 46, 0.32);

  &-content {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

</style>
