import axios from 'axios';
import store from '../store';
import axiosRetry from 'axios-retry';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_CONFIG_V6 = {
  baseURL: process.env.VUE_APP_API_URL_V6,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);
const httpClientV6 = axios.create(AXIOS_CONFIG_V6);

axiosRetry(httpClient, { retries: 3 });

httpClient.interceptors.response.use(
  response => response,
  error => {
    const config: { method: string; url: string } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

axiosRetry(httpClientV6, { retries: 3 });

httpClientV6.interceptors.response.use(
  response => response,
  error => {
    const config: { method: string; url: string } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

const getAllPackages = () => httpClientV6.get('/showcase/plans/');

const getExtendedPackages = () => httpClientV6.get('/showcase/packs/');

const getAllNodes = () => httpClient.get('access_nodes/nodes_v2');

const sendFeedback = (email: string, name: string, message: string, recaptchaToken: string, source: string = 'account.getblock.io') => {
  return httpClient.post(
    '/contacts/',
    {
      email,
      name,
      message: message + `\n\nUID: ${store.state.user.id}`,
      source,
    },
    {
      'axios-retry': {
        retries: 0,
      },
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const generateGift = (language: string = 'en') =>
  httpClient.post('/gifts/', { language }, { headers: { Authorization: `Bearer ${store.state.accessToken}` } });

const getGift = () =>
  httpClient.get('/gifts/', {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

export { getAllPackages, getExtendedPackages, getAllNodes, sendFeedback, generateGift, getGift };
