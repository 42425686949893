<script lang="ts" setup>
import {
  GetBlockUINotification,
  GetBlockUIButton,
  GetBlockUIIcon,
} from '@getblockui/core';
import { GetBlockUiContactForm } from '@getblockui/common';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { useCookie } from '@/composables/useCookie';
import { useI18n } from 'vue-i18n';
import { useDark } from '@vueuse/core';
import { useNotification } from '@/composables/useNotification';
import { IReCaptchaComposition, useReCaptcha } from 'vue-recaptcha-v3';
import { sendFeedback } from '@/api';
import { useGtm } from '@gtm-support/vue-gtm';
import StepScreen from '@/components/onboarding/StepScreen.vue';
import { useResize } from '@/composables/useResize';
import { NameCookie } from '@/types/composables/useCookie';
import { jwtParse } from '@/utils/jwtParse';
import support1 from '@/assets/images/contactModal/newSupport/support-1.png';
import support2 from '@/assets/images/contactModal/newSupport/support-2.png';
import support3 from '@/assets/images/contactModal/newSupport/support-3.png';
import support4 from '@/assets/images/contactModal/newSupport/support-4.png';
import ContactDedicatedManager from '@/components/modals/ContactDedicatedManager.vue';
import AccessTokenInfoModal from '@/components/modals/AccessTokenInfoModal.vue';
import { useAmplitude } from '@/composables/useAmlitude';
import { useMixpanel } from '@/composables/useMixpanel';
import { Endpoint } from '@/types/dashboard/sharedNodes/endpoint';

const { setCookie, getCookie, deleteCookie, deleteAllCookies } = useCookie();
let { t, locale } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const gtm = useGtm();
const { setUserAmplitude } = useAmplitude();
const { setUserMixpanel } = useMixpanel();

const modifiLocale = computed(() => {
  if (locale.value === 'en-gb' || locale.value === 'en') return 'en';
  return 'cn';
});

const SupportImages: Readonly<string[]> = [
  support1,
  support2,
  support3,
  support4,
] as const;

const { successData } = useNotification();

const { recaptchaLoaded, executeRecaptcha } = useReCaptcha() as IReCaptchaComposition;
const { windowWidth } = useResize();

type Cookie = 'strictly' | 'functional' | 'statistics' | 'marketing';

const notificationData = computed(() => {
  return {
    title: store.state.general.notificationMessage.title,
    description: store.state.general.notificationMessage.description,
  };
});

const isDark = useDark();
const successfullySentMessage = ref<boolean>(false);

const colorMode = computed(() => {
  return isDark.value ? 'dark' : 'light';
});

const closeModal = () => {
  store.commit('setContactModalDisplay', {
    display: false,
    contacts: store.state.general.contactModal.contacts,
  });
};
const closeAccessTokenInfoModal = () => {
  store.commit('setAccessTokenInfoModalDisplay', false);
};
const closeContactDedicatedManager = () => {
  store.commit('setContactModalPaymentManager', {display: false, type: store.state.general.contactModalPaymentManager.type});
};

const isNeededToOpenStepsModal = ref<boolean>(false);

const setLocale = () => {
  locale.value =
    getCookie('locale') === 'cn' || getCookie('locale') === 'zh-cn' ? 'zh-cn' : 'en-gb';
};

const setAccessToken = () => {
  if (getCookie('access_token'))
    store.commit('setAccessToken', getCookie('access_token'));
};

const getPackageKey = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('packageKey')) {
    const packageKey = urlParams.get('packageKey');
    store.commit('setBuyFromWebsite', {
      active: true,
      packageKey,
    });
    router.push('/buy-packages');
  }
};

const getAccountType = () => {
  if (getCookie('account_type'))
    store.commit('setAccountType', getCookie('account_type'));
};

const getAcceptedCookie = (): Cookie[] => {
  const acceptedCookiesCookieStorage: Cookie = getCookie('acceptedCookies') as Cookie;
  if (acceptedCookiesCookieStorage)
    return acceptedCookiesCookieStorage.split(',') as Cookie[];
  return [];
};

const checkAcceptedCookies = () => {
  const acceptedCookies = getAcceptedCookie();
  if (acceptedCookies.length === 0) {
    deleteAllCookies(['isTokenUpdated1']);
    return;
  }
  if (!acceptedCookies.includes('functional')) deleteCookie('locale', '.getblock.io');
  // if (acceptedCookies.includes('statistics')) gtm?.enable(true);
};

const isMessageSent = ref<boolean>(false);

const setUserData = () => {
  if (store.state.accessToken) {
    const userId = jwtParse(store.state.accessToken).sub;
    store.commit('setUserId', userId);
    store.commit('setUserAvatar', userId);
    setUserAmplitude(userId);
    setUserMixpanel(userId);
  }
};

// It is worth adding the ability to export interfaces from the ui-kit
export interface IArgsFromContactForm {
  subject: 'sales' | 'partnership' | 'support';
  email: string;
  name: string;
  company: string;
  message: string;
  selectedNodes?: string[];
}

const sendMessage = async (args: IArgsFromContactForm) => {
  try {
    isMessageSent.value = true;
    successfullySentMessage.value = false;
    let { subject, email, name, company, message, selectedNodes } = { ...args };
    let structuredMessage = `${subject.toUpperCase()}`;
    if (company) structuredMessage = structuredMessage + `\n\nUser company: ${company}`;
    if (selectedNodes)
      structuredMessage =
        structuredMessage + `\n\nUser selected nodes: ${selectedNodes.join(',')}`;
    structuredMessage = structuredMessage + `\n\nMessage: ${message}`;
    await recaptchaLoaded();
    const recaptchaToken = await executeRecaptcha('general_sendFeedback');
    await sendFeedback(email, name, structuredMessage, recaptchaToken);
    store.commit('setNotificationMessage', {
      ...successData('general_send-feedback'),
      type: 'high',
    });
    store.commit('setNotificationMessageDisplay', true);
    gtm?.trackEvent({
      event: 'send_message',
      userID: store.state.auth.userID ? store.state.auth.userID : 'not_registered_user',
    });
    successfullySentMessage.value = true;
  } catch (e) {
    const { errorData } = useNotification();
    store.commit('setNotificationMessage', {
      ...errorData('general_send-message'),
      type: 'error',
    });
    store.commit('setNotificationMessageDisplay', true);
  } finally {
    isMessageSent.value = false;
    store.commit('setContactModalDisplay', {
      display: false,
      contacts: store.state.general.contactModal.contacts,
    });
  }
};

onMounted(async () => {
  if (localStorage.getItem('isTokenUpdated') !== 'true') {
    const domain = {
      'https://account.getblock.dev': '.getblock.dev',
      'https://account.getblock.io': '.getblock.io',
      'http://localhost:8080': '.localhost',
    };
    // @ts-ignore
    deleteCookie('access_token', domain[window.location.origin]);
    deleteCookie('access_token');
    localStorage.setItem('isTokenUpdated', 'true');
  }
  setAccessToken();
  setUserData();
  await router.isReady();
  checkAcceptedCookies();
  getAccountType();
  getPackageKey();
  setLocale();
});

const isDataLoading = computed(() => store.state.general.loading);

const handleRenameEndpoint = async ({
                                      endpoint,
                                      newName,
                                      namespace,
                                      dedicatedNodeId,
                                    }: {
  endpoint: Endpoint;
  newName: string;
  namespace: 'shared' | 'dedicated';
  dedicatedNodeId: string;
}) => {
  await recaptchaLoaded();
  const recaptchaToken = await executeRecaptcha('endpoints_rename');
  if (namespace === 'shared') {
    store.dispatch('renameSharedEndpoint', {
      name: newName,
      id: endpoint?._key,
      recaptchaToken,
    });
    return;
  }
  store.dispatch('renameDedicatedEndpoint', {
    name: newName,
    accessTokenId: endpoint._key,
    dedicatedNodeId,
    recaptchaToken,
  });
};

// watch(
//   () => [isNeededToOpenStepsModal.value, isDataLoading.value],
//   async () => {
//     if (store.state.sharedNodes.endpoints.length !== 0) {
//       localStorage.setItem('isOnboardingPassed', 'true');
//     }
//     if (!isDataLoading.value) {
//       if (isNeededToOpenStepsModal.value) {
//         store.commit('setShowOnboarding', {
//           stepsModal: true,
//         });
//         localStorage.setItem('isOnboardingPassed', 'true');
//         isNeededToOpenStepsModal.value = false;
//       }
//
//       if (localStorage.getItem('isOnboardingPassed') !== 'true') {
//         await router.push('/');
//         store.commit('setShowOnboarding', {
//           stepsModal: true,
//         });
//         localStorage.setItem('isOnboardingPassed', 'true');
//       }
//     }
//   },
// );
</script>

<template>
  <div
    class="flex h-full min-h-screen"
    :class="{
      'bg-bg-additional dark:bg-bg-dark-additional': !route.path.includes(
        '/dedicated-nodes/create'
      ),
      'bg-bg-base dark:bg-bg-dark-base': route.path.includes('/dedicated-nodes/create'),
    }"
  >
    <router-view name="AuthLeftSide" />
    <router-view />

    <div
      v-show="store.state.general.contactModal.display"
      id="contactModal"
      class="contactModal"
      @click.self="closeModal"
    >
      <div
        class="rounded-24 relative bg-[#3d70eb] w-[568px] after:content-[''] after:absolute after:left-0 after:h-full after:w-[40%] after:bg-gradient-to-r after:from-[#79aff9] after:top-0 after:rounded-24 sm:w-full xs:w-full sm:rounded-t-[24px] sm:fixed xs:fixed xs:bottom-0 sm:bottom-0 sm:max-h-[80%] xs:rounded-t-[24px]"
      >
        <div class="flex items-center m-6 gap-6">
          <p class="text-text-color-bg z-20 text-xl leading-6 max-w-[170px] font-bold">
            {{ t('contactModal.title') }}
          </p>
          <div class="flex z-20 ml-auto">
            <div
              v-for="(support, index) in SupportImages"
              :key="support"
              :class="
                index === 0
                  ? '-right-[60px]'
                  : index === 1
                  ? '-right-10'
                  : index === 2
                  ? '-right-5'
                  : index === 3
                  ? ''
                  : ''
              "
              class="bg-[#ecf3fe] rounded-full relative h-12 w-12 overflow-hidden border-2 border-[#3d70ed]"
            >
              <img height="48" width="48" :alt="`support-${index + 1}`" :src="support" />
            </div>
          </div>
          <GetBlockUIButton
            class="shrink-0"
            color-type="secondary"
            icon-button
            size-type="medium"
            @click="closeModal"
          >
            <GetBlockUIIcon color-type="main" icon-name="x-close" />
          </GetBlockUIButton>
        </div>
        <GetBlockUiContactForm
          id="contactModal"
          :key="store.state.general.contactModal.contacts"
          :color-mode="colorMode"
          :locale="modifiLocale"
          :loading="isMessageSent"
          :place-open-modal="store.state.general.contactModal.contacts"
          :successfully-sent-message="successfullySentMessage"
          @send="sendMessage"
        />
      </div>
    </div>
    <div v-if="store.state.general.showOnboarding.stepsModal && windowWidth >= 1024">
      <StepScreen />
    </div>
    <GetBlockUINotification
      v-show="store.state.general.showNotificationMessage"
      class="max-w-[480px] fixed bottom-8 right-8 z-[15] sm:bottom-[92px] sm:max-w-[100%] sm:left-4 sm:right-4 sm:justify-between sm:w-[calc(100%-32px)] xs:bottom-[92px] xs:left-4 xs:right-4 xs:justify-between xs:w-[calc(100%-32px)] xs:max-w-[100%]"
      data-testid="notification"
      :info-icon="
        store.state.general.notificationMessage.type === 'attention' ||
        store.state.general.notificationMessage.title ===
          $t('notification.error.authentication.sign-in')
      "
      :main-type="store.state.general.notificationMessage.type"
      @delete-notification="store.commit('setNotificationMessageDisplay', false)"
    >
      <template #title>
        {{ notificationData.title }}
      </template>
      <template #default>
        <span v-html="notificationData.description" />
      </template>
    </GetBlockUINotification>
    <div v-show="store.state.general.contactModalPaymentManager.display">
      <ContactDedicatedManager @close-modal="closeContactDedicatedManager" />
    </div>
    <div v-show="store.state.general.accessTokenInfoModal.display">
      <AccessTokenInfoModal
        :key="store.state.general.accessTokenInfoModal.endpoint._key"
        :endpoint="store.state.general.accessTokenInfoModal.endpoint"
        @rename-endpoint="handleRenameEndpoint"
        @close="closeAccessTokenInfoModal"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contactModal {
  @apply flex items-center justify-center fixed top-0 left-0 h-full w-full;
  z-index: 30;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.24);
}
</style>
