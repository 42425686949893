import { ActionTree, Module, MutationTree } from 'vuex';
import { AuthActions, AuthMutations, AuthState } from '@/types/auth/store';
import { RootState } from '@/types/store';
import {
  changePassword,
  confirmEmail,
  forgotPassword,
  getNonceMetamask,
  googleLogin,
  signInMetamask,
  signUpEmail,
  signUpMetamask,
  signInEmail,
} from '@/api/auth';
import { useNotification } from '@/composables/useNotification';
import { useCookie } from '@/composables/useCookie';
import { Buffer } from 'buffer';
import { jwtParse } from '@/utils/jwtParse';
import { NameCookie } from '@/types/composables/useCookie';

const { setCookie } = useCookie();

export type State = AuthState;
export type Mutations = AuthMutations;
export type Actions = AuthActions;

const state: () => State = () => ({
  token: '',
  userID: '',
  ethereum: undefined,
  googleToken: '',
  accountEth: '',
  addressEth: '',
  nonce: '',
  signature: '',
  params: undefined,
  newPassword: '',
  email: '',
  accountDefinitionType: 'username',
  localeCookie: '',
  verifyYourEmailDisplay: false,
  ref: localStorage.getItem('ref') || '',
  teamRef: '',
  utmObject: {
    source: '',
    medium: '',
    campaign: '',
    content: '',
    term: '',
  },
});

const mutations: MutationTree<State> & Mutations = {
  setUtmVariables(state: State, { utmName, value }) {
    state.utmObject[utmName] = value;
  },
  setRef(state: State, ref) {
    state.ref = ref;
    localStorage.setItem('ref', ref);
  },
  setAuthToken(state: State, token) {
    state.token = token;
  },
  setID(state: State, userID) {
    state.userID = userID;
  },
  setGoogleToken(state: State, googleToken) {
    state.googleToken = googleToken;
  },
  setEthereum(state: State, ethereum) {
    state.ethereum = ethereum;
  },
  setAccountEth(state: State, account) {
    state.accountEth = account;
  },
  setAddressEth(state: State, address) {
    state.addressEth = address;
  },
  setNonce(state: State, nonce) {
    state.nonce = nonce;
  },
  setSignature(state: State, signature) {
    state.signature = signature;
  },
  setParams(state: State, params) {
    state.params = params;
  },
  setNewPassword(state: State, newPassword) {
    state.newPassword = newPassword.trim();
  },
  setEmail(state: State, email) {
    state.email = email;
  },
  changeAccountDefinitionType(state, value) {
    if (value === state.accountDefinitionType) return;
    state.accountDefinitionType = value;
  },
  setLocaleCookie(state: State, value) {
    state.localeCookie = value;
  },
  setVerifyYourEmailDisplay(state: State, value) {
    state.verifyYourEmailDisplay = value;
  },
  setTeamRef(state: State, team) {
    state.teamRef = team;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async googleAuth({ state, commit }, payload) {
    try {
      const res = await googleLogin({
        referral_user: payload.referral_user,
        token: state.googleToken,
        user_utm: payload.utm,
        team_invite: payload.team_invite,
        recaptchaToken: payload.recaptchaToken,
      });
      commit('setAccessToken', res.data);
      const domain = {
        'https://account.getblock.dev': '.getblock.dev',
        'https://account.getblock.io': '.getblock.io',
        'http://localhost:8080': '.localhost',
      };
      setCookie<NameCookie>('access_token', res.data, {
        // 7 days
        expires: new Date(Date.now() + 604800e3),
        // @ts-ignore
        domain: domain[window.location.origin],
      });
      commit('setAccountType', 'googleAuth');
      setCookie<NameCookie>('account_type', 'googleAuth');
      if (state.localeCookie) setCookie<NameCookie>('locale', state.localeCookie);
      commit('setID', jwtParse(res.data).sub);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_login'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async getNonce({ state, commit }, recaptchaToken) {
    try {
      const nonce = await getNonceMetamask(state.addressEth, recaptchaToken, state.teamRef);
      commit('setNonce', nonce.request.response);
      return 200;
    } catch (e) {
      // commit('setAccountEth', "");
      // commit('setAddressEth', "")
      // @ts-ignore
      if (e.response.status === 403) return 403;
      else {
        const { errorData } = useNotification();
        commit('setNotificationMessage', {
          ...errorData('authentication_login'),
          type: 'error',
        });
        commit('setNotificationMessageDisplay', true);
      }
    }
  },
  async getSignature({ state, commit }) {
    const buff = Buffer.from(state.nonce, 'utf-8');
    try {
      const signature = await state.ethereum.request({
        method: 'personal_sign',
        params: [buff.toString('hex'), state.accountEth],
      });
      commit('setSignature', signature);
      return 200
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_login'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
      // @ts-ignore
      return e.code as number
    }
  },
  async getTokenMetaMask({ state, commit }, recaptchaToken) {
    try {
      const res = await signInMetamask(state.addressEth, state.signature, state.nonce, recaptchaToken, state.utmObject);
      commit('setAccessToken', res.data);
      const domain = {
        'https://account.getblock.dev': '.getblock.dev',
        'https://account.getblock.io': '.getblock.io',
        'http://localhost:8080': '.localhost',
      };
      setCookie<NameCookie>('access_token', res.data, {
        // 7 days
        expires: new Date(Date.now() + 604800e3),
        // @ts-ignore
        domain: domain[window.location.origin],
      });
      commit('setAccountType', 'wallet');
      setCookie<NameCookie>('account_type', 'wallet');
      if (state.localeCookie) setCookie<NameCookie>('locale', state.localeCookie);
      commit('setID', jwtParse(res.data).sub);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_login'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async signUpMetamask({ state, commit }, recaptchaToken) {
    try {
      await signUpMetamask(state.addressEth, recaptchaToken, state.ref, state.utmObject, state.teamRef);
      commit('setIsRecentlyRegistered', true);
      return 200
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_login'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
      // @ts-ignore
      return e.response.status
    }
  },
  async getAccountsAndAddress({ state, commit }) {
    try {
      if (state.addressEth === '' || state.accountEth === '') {
        const accounts = await state.ethereum.request({ method: 'eth_requestAccounts' });
        commit('setAccountEth', accounts[0]);
        commit('setAddressEth', state.ethereum.selectedAddress);
      }
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_login'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async signInEmail({ state, commit }, recaptchaToken) {
    try {
      const res = await signInEmail(state.params, recaptchaToken);
      commit('setAccessToken', res.data);
      const domain = {
        'https://account.getblock.dev': '.getblock.dev',
        'https://account.getblock.io': '.getblock.io',
        'http://localhost:8080': '.localhost',
      };
      setCookie<NameCookie>('access_token', res.data, {
        // 7 days
        expires: new Date(Date.now() + 604800e3),
        // @ts-ignore
        domain: domain[window.location.origin],
      });
      commit('setAccountType', 'email');
      setCookie<NameCookie>('account_type', 'email');
      if (state.localeCookie) setCookie<NameCookie>('locale', state.localeCookie);
      commit('setID', jwtParse(res.data).sub);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_sign-in'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async signUpEmail({ state, commit }, recaptchaToken) {
    try {
      await signUpEmail(state.params, recaptchaToken);
      commit('setVerifyYourEmailDisplay', true);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_register'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async confirmEmail({ state, commit }, recaptchaToken) {
    try {
      const res = await confirmEmail(state.token, recaptchaToken);
      commit('setAccessToken', res.data);
      const domain = {
        'https://account.getblock.dev': '.getblock.dev',
        'https://account.getblock.io': '.getblock.io',
        'http://localhost:8080': '.localhost',
      };
      setCookie<NameCookie>('access_token', res.data, {
        // 7 days
        expires: new Date(Date.now() + 604800e3),
        // @ts-ignore
        domain: domain[window.location.origin],
      });
      commit('setAccountType', 'email');
      setCookie<NameCookie>('account_type', 'email');
      commit('setID', jwtParse(res.data).sub);
    } catch (e) {
      throw new Error();
    }
  },
  async changePassword({ state, commit }, recaptchaToken) {
    try {
      const res = await changePassword(state.newPassword, state.token, recaptchaToken);
      commit('setAccessToken', res.data);
      const domain = {
        'https://account.getblock.dev': '.getblock.dev',
        'https://account.getblock.io': '.getblock.io',
        'http://localhost:8080': '.localhost',
      };
      setCookie<NameCookie>('access_token', res.data, {
        // 7 days
        expires: new Date(Date.now() + 604800e3),
        // @ts-ignore
        domain: domain[window.location.origin],
      });
      commit('setAccountType', 'email');
      if (state.localeCookie) setCookie<NameCookie>('locale', state.localeCookie);
      setCookie<NameCookie>('account_type', 'email');
      commit('setID', jwtParse(res.data).sub);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_set-password'),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async forgotPassword({ state, commit }, recaptchaToken) {
    try {
      const username = state.accountDefinitionType === 'uid' ? state.userID : state.email;
      await forgotPassword(username, recaptchaToken, state.accountDefinitionType);
      const { successData } = useNotification();
      commit('setNotificationMessage', {
        ...successData('authentication_forgot-password'),
        type: 'high',
      });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', {
        ...errorData('authentication_reset-password'),
        type: 'error',
      });
    }
  },
};

const AuthModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
};

export default AuthModule;
