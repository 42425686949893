import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

const LoginWithWallet = () => import('@/views/authentication/LoginWithWallet');
const SignInWithEmail = () => import('@/views/authentication/SignInWithEmail');
const SignUp = () => import('@/views/authentication/SignUp');
const SignUpAffiliate = () => import('@/views/authentication/SignUpAffiliate');
const ForgotPassword = () => import('@/views/authentication/ForgotPassword');
const NewPassword = () => import('@/views/authentication/NewPassword');
const ConfirmEmail = () => import('@/views/authentication/СonfirmEmail');
const AuthLeftSide = () => import('@/components/AuthLeftSide');
const Account = () => import('@/views/Account');
const DedicatedNodeConfigurator = () => import('@/views/DedicatedNodeConfigurator');
const DedicatedNodes = () => import('@/views/dashboard/DedicatedNodes');
const General = () => import('@/views/profile/General');
const MyTeam = () => import('@/views/profile/MyTeam');
const MyBalance = () => import('@/views/profile/MyBalance');
const PaymentHistory = () => import('@/views/profile/PaymentHistory');
const ManageSubscriptions = () => import('@/views/profile/manageSubscriptions/ManageSubscriptions');
const UpdateSubscription = () => import('@/views/profile/manageSubscriptions/UpdateSubscription.vue');
const CancelSubscription = () => import('@/views/profile/manageSubscriptions/CancelSubscription.vue');
const AffiliateProgram = () => import('@/views/profile/AffiliateProgram');
const MediaAssets = () => import('@/views/profile/MediaAssets');
const SharedNodes = () => import('@/views/dashboard/SharedNodes');
const Dashboard = () => import('@/views/dashboard/Dashboard.vue');
const Checkout = () => import('@/views/Checkout');
const Onboarding = () => import('@/views/Onboarding.vue');


const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
  {
    path: '/',
    component: Account,
    beforeEnter: requireAuth,
    meta: {
      title: 'Account',
    },
    children: [
      {
        path: '',
        component: Dashboard,
        meta: {
          title: 'Shared Nodes',
        },
        children: [
          {
            path: '',
            component: SharedNodes,
            meta: {
              title: 'Shared Nodes',
            },
          },
          {
            path: '/dedicated-nodes',
            component: DedicatedNodes,
            meta: {
              title: 'Dedicated Nodes',
            },
          },
        ],
      },
      {
        path: '/dedicated-nodes/create',
        component: DedicatedNodeConfigurator,
        beforeEnter: requirePaymentPermission,
        meta: {
          title: 'Dedicated Node Configurator',
        },
      },
      {
        path: '/profile',
        component: General,
      },
      {
        path: '/profile/my-team',
        component: MyTeam,
        beforeEnter: requireTeamAccount,
      },
      {
        path: '/profile/my-balance',
        component: MyBalance,
      },
      {
        path: '/profile/payment-history',
        component: PaymentHistory,
        beforeEnter: requirePaymentPermission,
      },
      {
        path: '/profile/manage-subscriptions',
        component: ManageSubscriptions,
        beforeEnter: requirePaymentPermission,
      },
      {
        path: '/profile/affiliate-program',
        component: AffiliateProgram,
        beforeEnter: requirePersonalAccount,
      },
      {
        path: '/profile/media-assets',
        component: MediaAssets,
        beforeEnter: requirePersonalAccount,
      },
      {
        path: '/profile/manage-subscriptions/update-subscription/:updateLink',
        name: 'update-subscription',
        props: true,
        component: UpdateSubscription,
        beforeEnter: requirePaymentPermission,
      },
      {
        path: '/profile/manage-subscriptions/cancel-subscription/:cancelLink',
        name: 'cancel-subscription',
        props: true,
        component: CancelSubscription,
        beforeEnter: requirePaymentPermission,
      },
    ],
  },
  {
    path: '/sign-in',
    beforeEnter: checkIfAlreadyLoggedIn,
    components: {
      default: LoginWithWallet,
      AuthLeftSide,
    },
    meta: {
      title: 'Sign In',
    },
  },
  {
    path: '/sign-in-email',
    components: {
      default: SignInWithEmail,
      AuthLeftSide,
    },
    redirect: '/sign-in',
    meta: {
      title: 'Sign In Email',
    },
  },
  {
    path: '/forgot-password',
    components: {
      default: ForgotPassword,
      AuthLeftSide,
    },
    meta: {
      title: 'Forgot Password',
    },
  },
  {
    path: '/recovery/:recoveryToken',
    name: 'recovery-password',
    props: true,
    components: {
      default: NewPassword,
      AuthLeftSide,
    },
    meta: {
      title: 'Create Password',
    },
  },
  {
    path: '/sign-up',
    beforeEnter: checkIfAlreadyLoggedIn,
    components: {
      default: SignUp,
      AuthLeftSide,
    },
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/sign-up-affiliate',
    beforeEnter: checkIfAlreadyLoggedIn,
    components: {
      default: SignUpAffiliate,
      AuthLeftSide,
    },
    meta: {
      title: 'Affiliate Sign Up',
    },
  },
  {
    path: '/checkout/:checkoutLink',
    name: 'check-out',
    props: true,
    component: Checkout,
    beforeEnter: requirePaymentPermission,
  },
  {
    path: '/confirm/:confirmToken',
    name: 'mail-confirmation',
    props: true,
    components: {
      default: ConfirmEmail,
    },
    meta: {
      title: 'Email confirmation',
    },
  },
  {
    path: '/onboarding',
    component: Onboarding,
    meta: {
      title: 'Onboarding'
    }
  }
];

function requireAuth(to, from, next) {
  if (!store.state.accessToken) next('/sign-up');
  else next();
}

function checkIfAlreadyLoggedIn(to, from, next) {
  if (to.query.team_invite) {
    store.commit('setTeamRef', to.query.team_invite);
  }
  if (store.state.accessToken && !to.query.team_invite) next('/');
  else next();
}

function requirePersonalAccount(to, from, next) {
  if (!store.getters.isPersonalAccount) next('/profile/my-team');
  else next();
}

function requireTeamAccount(to, from, next) {
  if (store.getters.isPersonalAccount) next('/');
  else next();
}

function requirePaymentPermission(to, from, next) {
  if (!store.getters.hasPaymentPermission) next('/profile/my-team');
  else next();
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.query['login-marketplace']) {
    store.commit('setIsFaucetLogin', toRoute.query['login-marketplace']);
    store.commit('setFaucetBackLink', toRoute.query['back-link']);
  }

  if (toRoute.query.ref) {
    store.commit('setRef', toRoute.query.ref);
  }
  if (toRoute.query.openConfigurator) {
    store.commit('setIsNeedToShowDedicatedNodeConfigurator', true);
  }
  if (toRoute.query.utm_source) {
    store.commit('setUtmVariables', { utmName: 'source', value: toRoute.query.utm_source });
  }
  if (toRoute.query.utm_medium) {
    store.commit('setUtmVariables', { utmName: 'medium', value: toRoute.query.utm_medium });
  }
  if (toRoute.query.utm_campaign) {
    store.commit('setUtmVariables', { utmName: 'campaign', value: toRoute.query.utm_campaign });
    if (store.state.accessToken && toRoute.query.utm_campaign === 'referralprogram') next('/profile/affiliate-program');
  }
  if (toRoute.query.utm_content) {
    store.commit('setUtmVariables', { utmName: 'content', value: toRoute.query.utm_content });
  }
  if (toRoute.query.utm_term) {
    store.commit('setUtmVariables', { utmName: 'term', value: toRoute.query.utm_term });
  }
  window.document.title =
    toRoute.meta && toRoute.meta.title ? `${toRoute.meta.title} | GetBlock.io` : 'Account | GetBlock.io';
  window.scrollTo(0, 0);
  next();
});

export default router;
