<script lang="ts" setup>
import {
  GetBlockUIButton,
  GetBlockUIIcon,
  GetBlockUIInput,
  GetBlockUILoader,
  GetBlockUITextArea,
} from '@getblockui/core';
import { ref, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import support4 from '@/assets/images/contactModal/newSupport/support-4.png';
import { sendFeedback } from '@/api';
import { IReCaptchaComposition, useReCaptcha } from 'vue-recaptcha-v3';
import { useStore } from '@/store';
import { useNotification } from '@/composables/useNotification';
import { useGtm } from '@gtm-support/vue-gtm';

const { t } = useI18n();
const store = useStore();
const { recaptchaLoaded, executeRecaptcha } = useReCaptcha() as IReCaptchaComposition;
const { successData } = useNotification();
const gtm = useGtm();


const emits = defineEmits(['closeModal'])

const telegram = ref<string>('');
const message = ref<string>('');
const isMessageSending = ref<boolean>(false);
const telegramError = ref<boolean>(false);
const textAreaError = ref<boolean>(false);

const checkValidData = () => {
  if (!telegram.value.trim()) telegramError.value = true
}

const formationStructuredMessage = () => {
  let structuredMessage = store.state.general.contactModalPaymentManager.type === 'dedicated' ? 'DEDIC CONFIGURATOR' : 'PURCHASE'
  structuredMessage = structuredMessage + `\n\n Telegram: ${telegram.value}`;
  structuredMessage = structuredMessage + `\n\n Message: ${message.value}`;
  return structuredMessage
}

const send = async () => {
  if (isMessageSending.value) return
  checkValidData()
  if (telegramError.value || textAreaError.value) return
  try {
    isMessageSending.value = true
    const structuredMessage = formationStructuredMessage();
    await recaptchaLoaded();
    const recaptchaToken = await executeRecaptcha('general_sendFeedback');
    await sendFeedback(telegram.value, store.state.auth.userID, structuredMessage, recaptchaToken);
    store.commit('setNotificationMessage', {
      ...successData('general_send-feedback'),
      type: 'high',
    });
    store.commit('setNotificationMessageDisplay', true);
    gtm?.trackEvent({
      event: 'send_message_configurator',
      userID: store.state.auth.userID ? store.state.auth.userID : 'not_registered_user',
    });
  } catch (e) {
    const { errorData } = useNotification();
    store.commit('setNotificationMessage', {
      ...errorData('general_send-message'),
      type: 'error',
    });
    store.commit('setNotificationMessageDisplay', true);
  } finally {
    isMessageSending.value = false
    store.commit('setContactModalPaymentManager', {display: false, type: store.state.general.contactModalPaymentManager.type});
  }
}

const closeModal = () => {
  emits('closeModal')
}
</script>

<template>
  <div class="contactModal" @click.self="closeModal">
    <div
      class="xl:rounded-24 lg:rounded-24 md:rounded-24 sm:rounded-24 relative bg-[#3d70eb] w-[480px] after:content-[''] after:absolute after:left-0 after:h-full after:w-[40%] after:bg-gradient-to-r after:from-[#79aff9] after:top-0 after:rounded-24 sm:w-full xs:w-full sm:rounded-t-[24px] sm:fixed xs:fixed xs:bottom-0 sm:bottom-0 sm:max-h-[80%] xs:rounded-t-[24px]"
    >
      <div class="flex justify-between p-6 items-center relative z-20 ml-auto">
        <p :key="store.state.general.contactModalPaymentManager.type" class="max-w-[300px] text-xl leading-6 font-bold text-text-color-bg">{{ t(`contact-dedicated-manager.title-modal.${store.state.general.contactModalPaymentManager.type}`) }}</p>
        <GetBlockUIButton icon-button color-type="white" size-type="medium" @click="closeModal">
          <GetBlockUIIcon color-type="action" icon-name="x-close" />
        </GetBlockUIButton>
      </div>
      <div
        class="flex flex-col p-6 xl:rounded-24 lg:rounded-24 md:rounded-24 sm:rounded-t-24 xs:rounded-t-[24px] gap-6 relative z-20 xs:z-40 gb-bg-bg-base"
      >
        <p class="text-center leading-6 font-medium text-lg gb-text-text-main">{{ t('contact-dedicated-manager.title') }}</p>
        <div class="flex items-center xs:flex-col xs:gap-2">
          <div class="flex gap-4 items-center">
            <div class="rounded-full bg-[#EBF3FF] w-12 h-12 flex items-center justify-center overflow-hidden">
              <img width="48" height="48" :alt="`support-3`" :src="support4" />
            </div>
            <p class="leading-6 font-bold text-lg gb-text-text-main">{{ t(`contact-dedicated-manager.manager`) }}</p>
          </div>
          <a
            target="_blank"
            href="https://t.me/Wilfried_GetBlock"
            class="ml-auto xs:w-full cursor-pointer hover:opacity-60"
          >
            <div class="h-11 flex gap-2 leading-5 bg-[#00A3FF] rounded-12 items-center justify-center p-4">
              <GetBlockUIIcon color-type="color-bg" icon-name="telegram-multi" />
              <span class="leading-5 font-medium text-text-color-bg">{{ t('contact-dedicated-manager.buttons.telegram') }}</span>
            </div>
          </a>
        </div>
        <div class="flex gap-3 items-center">
          <div class="h-0.5 gb-bg-surface-separator w-full"></div>
          <span class="leading-5 font-medium text-text-secondary">{{ t('contact-dedicated-manager.or') }}</span>
          <div class="h-0.5 gb-bg-surface-separator w-full"></div>
        </div>
        <GetBlockUIInput
          v-model:input-value="telegram"
          icon-name="telegram-multi"
          input-name="telegram"
          :placeholder="t('contact-dedicated-manager.input.placeholder')"
          :error="telegramError"
          @input="telegramError = false"
          @keydown.enter="send"
          status-icon="none"
        >
          <template #label>
            <span class="font-bold text-xs text-[#9D9DA3]">{{ t('contact-dedicated-manager.input.label') }}</span>
          </template>
          <template #hint>
            <span v-if="telegramError" class="capitalize">{{ t('contact-dedicated-manager.input.error') }}</span>
          </template>
        </GetBlockUIInput>
        <GetBlockUITextArea
          v-model:input-name="message"
          v-model:input-value="message"
          :error="textAreaError"
          @input="textAreaError = false"
          @keydown.enter.ctrl="send"
          :placeholder="t('contact-dedicated-manager.text-aria.placeholder')"
        >
          <template #label>
            <span class="text-[#9D9DA3]">{{ t('contact-dedicated-manager.text-aria.label') }}</span>
          </template>
          <template #hint>
            <span v-if="textAreaError" class="capitalize">{{ t('contact-dedicated-manager.text-aria.error') }}</span>
          </template>
        </GetBlockUITextArea>
        <GetBlockUIButton color-type="tint" @click="send">
          <template v-if="!isMessageSending">
            <span class="m-auto font-medium">{{ t('contact-dedicated-manager.buttons.send') }}</span>
          </template>
          <template v-else>
            <GetBlockUILoader color-type="tint" class="m-auto" />
          </template>
        </GetBlockUIButton>
        <p class="text-xs text-text-secondary text-center leading-[18px]" v-html="t('contact-dedicated-manager.footer')"></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contactModal {
  @apply flex items-center justify-center fixed top-0 left-0 h-full w-full;
  z-index: 30;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.24);
  @media (max-width: 600px) {
    z-index: 9999999;
  }
}
</style>
